<template lang="pug">
formTitle Verduras y frutas
SubHeaderWizard(
  infoText="Selecciona las verduras y frutas que te gustaria incluir:"
)
.grid.grid-cols-3.gap-4.mt-4
  div(v-for="food in listVegetables", :key="food.id")
    FormBoxOption(
      :name="food.name",
      :image="food.image",
      :selected="food.selected",
      @click="onClickOption(food.id)"
    )
p.mt-6.text-center.text-yellow-600(v-show="disableNextButton") Selecciona al menos 1 opción
FormDirectionButtons.mt-6(
  v-on:back="$router.replace({ name: 'Quiz_2' })",
  v-on:next="goNextStep",
  :disableNext="disableNextButton"
)
</template>

<script>
import FormBoxOption from "@/components/wizard/form-box-selectable.wizard";
import FormDirectionButtons from "@/components/wizard/buttons-back-next.wizard";
import SubHeaderWizard from "@/components/wizard/sub-header.wizard";

export default {
  components: {
    FormBoxOption,
    FormDirectionButtons,
    SubHeaderWizard,
  },
  methods: {
    onClickOption(id) {
      this.$store.dispatch("ingredientsV2Store/setIngredient", id);
    },
    goNextStep() {
      this.$router.push({ name: "Quiz_4" });
    },
  },
  computed: {
    isValidForm() {
      return !!this.listVegetables.find((o) => o.selected);
    },
    disableNextButton() {
      if (this.isValidForm) return false;
      return true;
    },
    listVegetables() {
      return this.$store.getters["ingredientsV2Store/getVegetables"];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>